body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Hind Siliguri", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;*/
  font-family: "Hind Siliguri", sans-serif;
}

.ellipsisField {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableHeaderCell {
  font-weight: bold !important;
}

.tableBodyHeader {
  display: flex !important;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.tableBodyRow {
  display: flex !important;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.tableBodyRow .react-datepicker-popper .textAlignRight {
  text-align: right !important;
}

.alignItemsCenter {
  display: flex;
  align-items: center;
}

.flex1 {
  flex: 1 !important;
}

.fontBold {
  font-weight: bold !important;
}

.teamDeadlineDatePickerContainer {
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
}

.teamDeadlineDatePickerContainer input:focus {
  outline: 0px !important;
}

.teamDeadlineDatePickerContainer .react-datepicker-wrapper {
  display: flex !important;
  align-items: center !important;
}

.teamDeadlineDatePickerContainer input {
  width: 90% !important;
  border: 1px solid lightgray !important;
  border-radius: 4px !important;
  padding: 5px 10px !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.teamDeadlineDatePickerContainer .button {
  padding: 6px 10px !important;
  font-size: 14px !important;
}

/* ---------------------------------Workroom current jobs columns------------------------------- */

.wkcj1 {
  width: 5% !important;
  flex-shrink: 0 !important;
}
.wkcj2 {
  width: 5% !important;
  flex-shrink: 0 !important;
}
.wkcj3 {
  width: 27% !important;
  flex-shrink: 0 !important;
}
.wkcj4 {
  width: 10% !important;
  flex-shrink: 0 !important;
}
.wkcj5 {
  width: 13% !important;
  flex-shrink: 0 !important;
}
.wkcj6 {
  width: 6% !important;
  flex-shrink: 0 !important;
}
.wkcj7 {
  width: 10.5% !important;
  flex-shrink: 0 !important;
}
.wkcj8 {
  width: 10.5% !important;
  flex-shrink: 0 !important;
}
.wkcj9 {
  width: 13% !important;
  flex-shrink: 0 !important;
}

/* -------------------------------------Workroom Archived Jobs------------------------------ */

.wkaj1 {
  width: 5% !important;
  flex-shrink: 0 !important;
}
.wkaj2 {
  width: 5% !important;
  flex-shrink: 0 !important;
}
.wkaj3 {
  width: 45% !important;
  flex-shrink: 0 !important;
}
.wkaj4 {
  width: 10% !important;
  flex-shrink: 0 !important;
}
.wkaj5 {
  width: 10% !important;
  flex-shrink: 0 !important;
}
.wkaj6 {
  width: 10% !important;
  flex-shrink: 0 !important;
}
.wkaj7 {
  width: 15% !important;
  flex-shrink: 0 !important;
}

/* -------------------------------------Workroom Jobs on hold------------------------------ */

.wkjh1 {
  width: 5% !important;
  flex-shrink: 0 !important;
}
.wkjh2 {
  width: 5% !important;
  flex-shrink: 0 !important;
}
.wkjh3 {
  width: 45% !important;
  flex-shrink: 0 !important;
}
.wkjh4 {
  width: 10% !important;
  flex-shrink: 0 !important;
}
.wkjh5 {
  width: 10% !important;
  flex-shrink: 0 !important;
}
.wkjh6 {
  width: 10% !important;
  flex-shrink: 0 !important;
}
.wkjh7 {
  width: 15% !important;
  flex-shrink: 0 !important;
}

/* -------------------------------------My Prioritized Jobs------------------------------ */

.prjb1 {
  width: 5% !important;
  flex-shrink: 0 !important;
}
.prjb2 {
  width: 5% !important;
  flex-shrink: 0 !important;
}
.prjb3 {
  width: 35% !important;
  flex-shrink: 0 !important;
}
.prjb4 {
  width: 13% !important;
  flex-shrink: 0 !important;
}
.prjb5 {
  width: 7% !important;
  flex-shrink: 0 !important;
}
.prjb6 {
  width: 10% !important;
  flex-shrink: 0 !important;
}
.prjb7 {
  width: 10% !important;
  flex-shrink: 0 !important;
}
.prjb8 {
  width: 15% !important;
  flex-shrink: 0 !important;
}

/* -------------------------------------Owned By Me Jobs------------------------------ */

.owbm1 {
  width: 5% !important;
  flex-shrink: 0 !important;
}
.owbm2 {
  width: 5% !important;
  flex-shrink: 0 !important;
}
.owbm3 {
  width: 35% !important;
  flex-shrink: 0 !important;
}
.owbm4 {
  width: 15% !important;
  flex-shrink: 0 !important;
}
.owbm5 {
  width: 7% !important;
  flex-shrink: 0 !important;
}
.owbm6 {
  width: 10% !important;
  flex-shrink: 0 !important;
}
.owbm7 {
  width: 10% !important;
  flex-shrink: 0 !important;
}
.owbm8 {
  width: 13% !important;
  flex-shrink: 0 !important;
}

/* -------------------------------------Team Update Priority------------------------------ */

.tup1 {
  width: 6% !important;
  flex-shrink: 0 !important;
}
.tup2 {
  width: 6% !important;
  flex-shrink: 0 !important;
}
.tup3 {
  width: 17% !important;
  flex-shrink: 0 !important;
}
.tup4 {
  width: 21% !important;
  flex-shrink: 0 !important;
}
.tup5 {
  width: 10% !important;
  flex-shrink: 0 !important;
}
.tup6 {
  width: 10% !important;
  flex-shrink: 0 !important;
}
.tup7 {
  width: 15% !important;
  flex-shrink: 0 !important;
}
.tup8 {
  width: 15% !important;
  flex-shrink: 0 !important;
}

/* -------------------------------------Team Assign User------------------------------ */

.tau1 {
  width: 6% !important;
  flex-shrink: 0 !important;
}
.tau2 {
  width: 6% !important;
  flex-shrink: 0 !important;
}
.tau3 {
  width: 17% !important;
  flex-shrink: 0 !important;
}
.tau4 {
  width: 30% !important;
  flex-shrink: 0 !important;
}
.tau5 {
  width: 20% !important;
  flex-shrink: 0 !important;
}
.tau6 {
  width: 15% !important;
  flex-shrink: 0 !important;
}

/* -------------------------------------Team Set Deadline------------------------------ */

.tsd1 {
  width: 6% !important;
  flex-shrink: 0 !important;
}
.tsd2 {
  width: 6% !important;
  flex-shrink: 0 !important;
}
.tsd3 {
  width: 20% !important;
  flex-shrink: 0 !important;
}
.tsd4 {
  width: 25% !important;
  flex-shrink: 0 !important;
}
.tsd5 {
  width: 13% !important;
  flex-shrink: 0 !important;
}
.tsd6 {
  width: 10% !important;
  flex-shrink: 0 !important;
}
.tsd7 {
  width: 20% !important;
  flex-shrink: 0 !important;
}

.tableBodyRow .tsd7 {
  padding: 0px !important;
}

/* -------------------------------------Team Edit Ticket------------------------------ */

.tet1 {
  width: 6% !important;
  flex-shrink: 0 !important;
}
.tet2 {
  width: 6% !important;
  flex-shrink: 0 !important;
}
.tet3 {
  width: 20% !important;
  flex-shrink: 0 !important;
}
.tet4 {
  width: 35% !important;
  flex-shrink: 0 !important;
}
.tet5 {
  width: 13% !important;
  flex-shrink: 0 !important;
}
.tet6 {
  width: 10% !important;
  flex-shrink: 0 !important;
}
.tet7 {
  width: 10% !important;
  flex-shrink: 0 !important;
}
